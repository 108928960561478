<template>
  <v-navigation-drawer :model-value="leftDrawerOpen" temporary @update:model-value="toggleLeftDrawer">
    <v-list-item
      v-if="authenticated"
      :prepend-avatar="user.avatar"
      class="mt-3"
      :title="user && user.name"
    ></v-list-item>

    <v-list density="compact" nav>
      <v-list-item
        v-for="item in items"
        :key="item.key"
        :prepend-icon="item.icon"
        :title="item.text"
        :value="item.key"
        :to="item.to"
      ></v-list-item>
      <v-list-item
        v-if="authenticated"
        :title="$i18n.messages[$i18n.locale].leftNavigationDrawer.logout"
        prepend-icon="mdi-exit-run"
        exact
        @click="logout"
      >
      </v-list-item>
    </v-list>

    <LogoIcon class="LeftNavigationDrawer__logo" @click="changeRoute" />
  </v-navigation-drawer>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapState } from 'vuex';
import { AUTH_MENU, USER_MENU } from '../constants.js';

import LogoIcon from '/resources/img/logo.svg?component';

export default {
  name: 'LeftNavigationDrawer',

  components: {
    LogoIcon,
  },

  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),

    ...mapState('ui', {
      leftDrawerOpen: (state) => state.leftDrawerOpen,
    }),

    items() {
      const { $i18n, authenticated } = this;
      return authenticated ? USER_MENU({ $i18n }) : AUTH_MENU({ $i18n });
    },
  },

  methods: {
    ...mapActions({
      toggleLeftDrawer: 'ui/toggleLeftDrawer',
      logout: 'auth/logout',
    }),

    changeRoute() {
      this.$router
        .push({
          name: 'home',
          query: { filters: {} },
        })
        .catch((e) => e);
    },
  },
};
</script>

<style lang="sass" scoped>
.LeftNavigationDrawer
  &__logo
    width: 100%
    max-height: 100px
    padding: 0 2rem
    position: absolute
    bottom: 0
    cursor: pointer
</style>
