// general routes
const Home = () => import(/* webpackChunkName: "js/HomePage" */ '@pages/Home.vue');
const About = () => import(/* webpackChunkName: "js/AboutPage" */ '@pages/About.vue');
const Terms = () => import(/* webpackChunkName: "js/TermsPage" */ '@pages/Terms.vue');
const Dashboard = () => import(/* webpackChunkName: "js/DashboardPage" */ '@pages/Dashboard.vue');
const Login = () => import(/* webpackChunkName: "js/LoginPage" */ '@pages/Login.vue');
const ForgotPassword = () => import(/* webpackChunkName: "js/ForgotPasswordPage" */ '@pages/ForgotPassword.vue');
const ResetPassword = () => import(/* webpackChunkName: "js/ResetPasswordPage" */ '@pages/ResetPassword.vue');
const Register = () => import(/* webpackChunkName: "js/RegisterPage" */ '@pages/Register.vue');
const VerifyEmail = () => import(/* webpackChunkName: "js/VerifyEmailPage" */ '@pages/VerifyEmail.vue');

const Settings = () => import(/* webpackChunkName: "js/SettingsPage" */ '@pages/settings/Settings.vue');
const Profile = () => import(/* webpackChunkName: "js/ProfilePage" */ '@pages/settings/Profile.vue');
const Password = () => import(/* webpackChunkName: "js/PasswordPage" */ '@pages/settings/Password.vue');
const Account = () => import(/* webpackChunkName: "js/AccountPage" */ '@pages/settings/Account.vue');
const Wishes = () => import(/* webpackChunkName: "js/WishesPage" */ '@pages/wishes/Wishes.vue');
const NewProduct = () => import(/* webpackChunkName: "js/NewProductPage" */ '@/views/products/NewProduct.vue');
const EditProduct = () => import(/* webpackChunkName: "js/EditProductPage" */ '@/views/products/EditProduct.vue');
const UserProductsPage = () =>
  import(/* webpackChunkName: "js/UserProductsPage" */ '@/views/products/UserProductsPage.vue');
const ListRoom = () => import(/* webpackChunkName: "js/ListRoom" */ '@/views/chat/ListRoom.vue');
const Room = () => import(/* webpackChunkName: "js/Room" */ '@/views/chat/Room.vue');
const NotFound = () => import(/* webpackChunkName: "js/NotFoundPage" */ '@pages/NotFound.vue');

const AdminLayout = () => import(/* webpackChunkName: "js/AdminLayoutPage" */ '@/layouts/AdminLayout.vue');
const Admin = () => import(/* webpackChunkName: "js/AdminPage" */ '@/views/admin/Admin.vue');
const Users = () => import(/* webpackChunkName: "js/UsersPage" */ '@/views/admin/Users.vue');
const Roles = () => import(/* webpackChunkName: "js/RolesPage" */ '@/views/admin/Roles.vue');
const Permissions = () => import(/* webpackChunkName: "js/PermissionsPage" */ '@/views/admin/Permissions.vue');
const AppSettings = () => import(/* webpackChunkName: "js/AppSettingsPage" */ '@/views/admin/AppSettings.vue');
const Advertisements = () => import(/* webpackChunkName: "js/AdvertisementsPage" */ '@/views/admin/Advertisements.vue');
const PhpInfo = () => import(/* webpackChunkName: "js/PhpInfoPage" */ '@/views/admin/PhpInfo.vue');

const ProductSingle = () => import(/* webpackChunkName: "js/SingleProductPage" */ '@/views/products/SingleProduct.vue');

const MfaConfirm = () => import(/* webpackChunkName: "js/MfaConfirmPage" */ '@/views/pages/MfaConfirm.vue');
import { defineAsyncComponent } from 'vue';

import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import roleAdmin from '@/middleware/roleAdmin';
import roleSuperAdmin from '@/middleware/roleSuperAdmin';
import roleUser from '@/middleware/roleUser';

export default [
  {
    path: '/',
    // component: () => Home,
    component: Home,
    name: 'home',
  },
  {
    path: '/about',
    component: About,
    name: 'about',
  },
  {
    path: '/terms',
    component: Terms,
    name: 'terms',
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/:productUuid',
    component: ProductSingle,
    name: 'single-product',
  },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path: '',
        name: 'admin',
        component: Admin,
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
      },
      {
        path: 'permissions',
        name: 'permissions',
        component: Permissions,
      },
      {
        path: 'phpinfo',
        name: 'phpinfo',
        component: defineAsyncComponent(() => import('@/views/admin/PhpInfo.vue')),
      },
      {
        path: 'app-settings',
        name: 'app-settings',
        component: AppSettings,
      },
      {
        path: 'advertisements',
        name: 'advertisements',
        component: Advertisements,
      },
    ],
    meta: {
      middleware: [roleAdmin],
    },
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/reset-password/:token',
    props: (route) => ({
      token: route.params.token,
      email: route.query.email,
    }),
    component: ResetPassword,
    name: 'reset-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/register',
    component: Register,
    name: 'register',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/verify-email/:id/:hash',
    props: (route) => ({
      id: route.params.id,
      hash: route.params.hash,
    }),
    component: VerifyEmail,
    name: 'verify-email',
  },
  {
    path: '/catalogs/:catalog',
    component: Home,
    name: 'catalogs',
    children: [
      {
        path: '/catalogs/:catalog/:catalogChild1',
        component: Home,
        children: [
          {
            path: '/catalogs/:catalog/:catalogChild1/:catalogChild2',
            component: Home,
          },
        ],
      },
    ],
  },
  {
    path: '/wishes',
    component: Wishes,
    name: 'wishes',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/my-showcase',
    component: UserProductsPage,
    name: 'my-showcase',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/my-showcase/edit/:productUuid',
    component: EditProduct,
    name: 'my-showcase-edit',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/new-ad',
    component: NewProduct,
    name: 'new-ad',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/messenger',
    component: ListRoom,
    name: 'messenger',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/channel/:roomId',
    component: Room,
    name: 'channel',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/settings',
    component: Settings,
    redirect: {
      name: 'account',
    },
    name: 'settings',
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: 'account',
        component: Account,
        name: 'account',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'profile',
        component: Profile,
        name: 'profile',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'password',
        component: Password,
        name: 'password',
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: '/mfa-confirm',
    component: MfaConfirm,
    name: 'mfa-confirm',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
];
