export default {
  userIcon: {
    tooltipText: 'Войдите в аккаунт',
  },
  home: {
    welcomeText: 'Доброе пожаловать',
    thanksText: 'Спасибо что присоединились!',
  },
  leftNavigationDrawer: {
    login: 'Войти',
    register: 'Регистрация',
    account: 'Аккаунт',
    logout: 'Выйти',
    wishes: 'Избранное',
    myShowCase: 'Мои объявления',
    createProduct: 'Новое объявление',
    messenger: 'Сообщения',
  },
  login: {
    emailLabel: 'Email',
    passwordLabel: 'Пароль',
    forgotPasswordText: 'Забыли пароль?',
    rememberCheckbox: 'Запомнить меня',
    loginBtnText: 'Войти',
    registerAccountBreaf: 'У вас еще нет аккаунта на сайте?',
    registerAccountText: 'Регистрация',
    socialLoginText: 'или',
    credentialError: 'Логин или пароль неверные.',
    badContent: 'Адрес электронной почты неверный',
    google: 'Войти с google',
  },
  register: {
    socialLoginText: 'Или зарегестрироваться через',
    nameLabel: 'Логин',
    passwordLabel: 'Пароль',
    passwordConfirmLabel: 'Подтвердить пароль',
    registerBtnText: 'Регистрация',
  },
  forgotPassword: {
    h1: 'Форма сброса пароля',
    text: 'Введите ваш email и на него будет отправлена ссылка для восстановления пароля',
    successBtn: 'Сбросить пароль',
  },
  resetPassword: {
    h1: 'Придумайте новый пароль',
    text: 'Придумайте новый пароль и сохраните его.',
    successBtn: 'Сохранить пароль',
    passwordLabel: 'Пароль',
    passwordRepeatLabel: 'Повторите пароль',
  },
  appSwitchButton: {
    tooltipText: 'Изменить вид',
  },
  search: {
    label: 'Начните писать для поиска...',
  },
  notFound: {
    h1: 'Похоже что мы не смогли ничего найти по вашему запросу',
    subtext: 'Вернитесь на главную страницу, чтобы продолжить поиск',
    btn: 'Вернуться на главную',
    emptySearchProductText: 'К сожалению ничего не найдено, попробуйте изменить ваш запрос',
    emptyWishlistText: 'Ваш список избранного пуст',
  },
  appNav: {
    wishTooltip: 'Избранное',
    messengerTooltip: 'Сообщения',
  },
  newProductForm: {
    h1: 'Создать новое объявление',
    nameStep: 'Название товара',
    nameLabel: 'Напишите что вы продаете',
    cityStep: 'Город',
    citiesLabel: 'Выберите город',
    descriptionStep: 'Описание и цена',
    descriptionLabel: 'Опишите ваш товар',
    fileStep: 'Добавьте фото',
    fileLabel: 'Загрузите фото вашего товара',
    addressLabel: 'Введите адрес',
    priceLabel: 'Цена',
    noData: 'Город не найден',
    required: 'Обязательное поле',
    createError: 'Что то пошло не так',
    successfullyCreate: 'Отлично! Ваш товар успешно создан. Дождитесь проверки от модератора.',
    selectCatalog: 'Выбрать каталог',
    selectCatalogDescription: 'Выберите категорию к которой относится ваш товар.',
    editCategoryBtn: 'Изменить категорию',
    saveBtn: 'Сохранить',
    nextBtn: 'Далее',
    prevBtn: 'Назад',
  },
  editProductForm: {
    h1: 'Редактирование объявления',
    nameLabel: 'Напишите что вы продаете',
    citiesLabel: 'Выберите город',
    descriptionLabel: 'Опишите ваш товар',
    fileLabel: 'Загрузите фото вашего товара',
    addressLabel: 'Введите адрес',
    priceLabel: 'Цена',
    noData: 'Город не найден',
    required: 'Обязательное поле',
    createError: 'Что то пошло не так',
    successfullyCreate: 'Отлично! Ваш товар успешно создан. Дождитесь проверки от модератора.',
    selectCatalog: 'Выбрать каталог',
    selectCatalogDescription: 'Выберите категорию к которой относится ваш товар.',
    editCategoryBtn: 'Изменить категорию',
    saveBtn: 'Сохранить',
    editH1: 'редактировать страницу',
    deleteImage: 'Удалить изображение',
  },
  errors: {
    error: 'Что то пошло не так',
  },
  chat: {
    messageCounter: '0 сообщений | 1 сообщение | {count} сообщений',
    messageInputPlaceholder: 'Введите ваше сообщение',
  },
  filters: {
    title: 'Фильтр',
    placesFilterTitle: 'Фильтр по городам',
    categoriesFilterTitle: 'Применить фильтры',
    regionLabel: 'Регион',
    cityLabel: 'Город',
    applyFiltersBtn: 'Применить фильтры',
    resetAllFiltersBtn: 'Сбросить все фильтры',
  },
  categories: {
    showCategoriesDropdownBtn: 'Категории',
  },
  viewsPagesSettingsPassword: {
    enable2faLabel: 'Включить двухфакторную авторизацию',
    enable2faBtn: 'Включить двухфакторную авторизацию',
    p1: 'Двухфакторная аутентификация (2FA) — это распространенный способ добавить дополнительный уровень безопасности вашей учетной записи. Если в вашей учетной записи включена 2FA, при входе в систему вам будет предложено ввести сгенерированный код (одноразовый код) для завершения аутентификации.',
    p2: 'Чтобы использовать 2FA, вам понадобится генератор одноразового кода, например Google Authenticator, Authy или одноразовый пароль.',
    p3: '1. Отсканируйте QR-код с помощью выбранного вами приложения-генератора тайм-кода.',
    p4: '2. Введите сгенерированный код в поле «Одноразовый пароль» и нажмите «Подтвердить и включить двухфакторную аутентификацию».',
    label: 'Введите 6-значный код из одного генератора тайм-кода.',
    submitBtn: 'Подтвердите и включите 2FA',
    requiredInput: 'Поле, обязательное для заполнения',
    confirmh1: '2FA успешно включена',
    confirmp1:
      'В вашей учетной записи теперь настроена двухфакторная аутентификация. При следующем входе в систему после ввода адреса электронной почты и пароля вам будет предложено ввести одноразовый код, сгенерированный выбранным вами приложением. Просто откройте приложение-генератор одноразового кода, введите сгенерированный код до истечения таймера и нажмите «Отправить».',
    confirmp2: 'Сохраните коды восстановления',
    confirmp3:
      'Используйте эти одноразовые коды восстановления для доступа к своей учетной записи, когда вы можете получать коды из приложения для аутентификации:',
    confirmBtn: 'Понятно!',
    errors: {
      invalid_code: 'Предоставленный код недействителен.',
      invalid_recovery_code: 'Предоставленный код восстановления недействителен.',
      throttle: 'Слишком много попыток. Пожалуйста, подождите, прежде чем повторить попытку.',
    },
  },
  mfaConfirm: {
    h1: '2FA-аутентификация',
    btnText: 'Отправлять',
    recoveryText: 'Возникли проблемы? Используйте коды восстановления.',
    recoveryH1: 'Восстановление 2FA',
    recoveryInfoText: 'Введите код восстановления, который вы получили при настройке двухфакторной аутентификации.',
    recoveryLabel: 'Введите код восстановления',
  },
  productSingle: {
    createdOn: 'Создано:',
    sendMsgBtn: 'Отправить сообщение',
  },
  productStatus: {
    statusLabel: 'Статус',
    new: 'Новый',
    moderate: 'На проверке у модератора',
    active: 'Активное',
    sold: 'Продано',
    reserved: 'Зарезервировано',
    disabled: 'Отключено',
    canceled: 'Отмененное',
  },
  settings: {
    account: 'Аккаунт',
    profile: 'Профиль',
    security: 'Защита',
  },
  password: {
    passwordH2: 'Пароль',
    mfaH2: '2FA авторизация',
    mfaDisableText: 'У вас включена двухфакторная аутентификация. Для отключения - нажмите кнопку ниже.',
    oldPasswordLabel: 'Старый пароль',
    passwordLabel: 'Новый пароль',
    passwordConfirmLabel: 'Подтвердите новый пароль',
    mfaDisableTextBtn: 'Отключить 2FA',
    recoveryCodesLabel: 'Ваши коды восстановления',
  },
  profile: {
    emailVerified: 'Проверено: ',
    emailNotVerified: 'Не проверено!',
    nameLabel: 'Имя',
    surnameLabel: 'Фамилия',
    phoneNumber: 'Телефон',
    city: 'Город',
    street: 'Улица',
    birthdayDate: 'Дата рождения',
    saveBtn: 'Сохранить',
    h2: 'Профиль',
    cancelBtn: 'Отменить',
  },
  socialShare: {
    shareTitle: 'Делясь этим объявлением, вы помогаете нашему сайту совершенствоваться.',
  },
  verifyEmail: {
    titleConfirmed: 'Ваш адрес электронной почты уже подтвержден',
    titleNotConfirmed: 'Пожалуйста, подтвердите проверку вашей электронной почты',
    goHomeBtn: 'Перейти на главную страницу',
    verifyBtn: 'Нажмите, чтобы подтвердить',
    verifyBtnError: 'Упс! Отправить подтверждение?',
  },
  googleAnalytics: {
    text: 'Мы используем файлы cookie для улучшения пользовательского опыта. Вы даете свое согласие на использование файлов cookie, нажав кнопку «Принять все файлы cookie». Вы также можете отказаться.',
    consentText: 'Принять все файлы cookie',
    refusalText: 'Отклонить',
  },
  sameProductsInTheCity: {
    h2: 'Похожие товары в этом городе',
  },
  sameProducts: {
    h2: 'Похожие товары в этой категории',
  },
};
