export default {
  userIcon: {
    tooltipText: 'Please enter to account',
  },
  home: {
    welcomeText: 'Welcome',
    thanksText: 'Thank you for being a member!',
  },
  leftNavigationDrawer: {
    login: 'Login',
    register: 'Register',
    account: 'Account',
    logout: 'Logout',
    wishes: 'My wish list',
    myShowCase: 'My showcase',
    createProduct: 'Create new ad',
    messenger: 'Messenger',
  },
  login: {
    emailLabel: 'Email',
    passwordLabel: 'Password',
    forgotPasswordText: 'Forgot password?',
    rememberCheckbox: 'Remember me',
    loginBtnText: 'Login',
    registerAccountBreaf: "Don't have an account?",
    registerAccountText: 'Register',
    socialLoginText: 'Or',
    credentialError: 'The provided credentials are incorrect.',
    badContent: 'The email must be a valid email address.',
    google: 'Continue with google',
  },
  register: {
    socialLoginText: 'Or register with',
    nameLabel: 'Name',
    passwordLabel: 'Password',
    passwordConfirmLabel: 'Confirm password',
    registerBtnText: 'Register',
  },
  forgotPassword: {
    h1: 'Reset password form',
    text: 'Enter your email and a link to reset your password will be sent to it',
    successBtn: 'Send email',
  },
  resetPassword: {
    h1: 'Create a new password',
    text: 'Create a new password and save it.',
    successBtn: 'Save password',
    passwordLabel: 'Password',
    passwordRepeatLabel: 'Repeat password',
  },
  appSwitchButton: {
    tooltipText: 'Change view',
  },
  search: {
    label: 'Type to search...',
  },
  notFound: {
    h1: 'Looks like you`ve found the doorway to the great nothing',
    subtext: 'Sorry about that! Please visit our hompage to get where you need  to go.',
    btn: 'Go to main',
    emptySearchProductText: "Unfortunately we didn't find anything. Try changing your request.",
    emptyWishlistText: 'Your wish list is currently empty.',
  },
  appNav: {
    wishTooltip: 'Your wishes',
    messengerTooltip: 'Your messages',
  },
  newProductForm: {
    h1: 'Create new ad',
    nameStep: 'Product name',
    nameLabel: 'Title, write what you are selling.',
    cityStep: 'Select city',
    citiesLabel: 'Choose city',
    descriptionStep: 'Describe your product and add a price.',
    descriptionLabel: 'Describe the item you are selling.',
    fileStep: 'Add a photo',
    fileLabel: 'Upload a photo of your product',
    addressLabel: 'Enter an address if necessary',
    priceLabel: 'Price',
    noData: 'City not found',
    required: 'Field is required',
    createError: 'Something went wrong',
    successfullyCreate: 'Great! Your product has been successfully created!',
    selectCatalog: 'Select catalog',
    selectCatalogDescription: 'Select the category in which your product will be displayed.',
    editCategoryBtn: 'Change category',
    saveBtn: 'Save',
    nextBtn: 'Next',
    prevBtn: 'Previous',
  },
  editProductForm: {
    h1: 'Create new ad',
    nameLabel: 'Title, write what you are selling.',
    citiesLabel: 'Choose city',
    descriptionLabel: 'Describe the item you are selling.',
    fileLabel: 'Upload a photo of your product',
    addressLabel: 'Enter an address if necessary',
    priceLabel: 'Price',
    noData: 'City not found',
    required: 'Field is required',
    createError: 'Something went wrong',
    successfullyCreate: 'Great! Your product has been successfully created!',
    selectCatalog: 'Select catalog',
    editCategoryBtn: 'Change category',
    saveBtn: 'Save',
    editH1: 'edit page',
    deleteImage: 'Delete image from gallery',
  },
  errors: {
    error: 'Something went wrong',
  },
  chat: {
    messageCounter: '0 messages | 1 message | {count} messages',
    messageInputPlaceholder: 'Type your message',
  },
  filters: {
    title: 'Filter results',
    placesFilterTitle: 'Choose place',
    categoriesFilterTitle: 'Selected filters',
    regionLabel: 'Region',
    cityLabel: 'City',
    applyFiltersBtn: 'Apply filters',
    resetAllFiltersBtn: 'Reset all filters',
  },
  categories: {
    showCategoriesDropdownBtn: 'All categories',
  },
  viewsPagesSettingsPassword: {
    enable2faLabel: 'Enable 2FA auth',
    enable2faBtn: 'Enable Two-factor Authentication',
    p1: 'Two-factor authentication (2FA) is a common way to add an extra layer of security on you account. If 2FA is enabled on your account, during login you will be asked to enter a generated code (one time code) to complete authentication.',
    p2: 'To use 2FA you will need a One Time Code generator such as Google Authenticator, Authy, or One Time Password.',
    p3: '1. Scan the QR code with your chosen one time code generator app.',
    p4: '2. Enter the generated code into the One Time Passcode box and click Confirm and Enable Two-Factor.',
    label: 'Enter 6-digit code from one Time Code generator',
    submitBtn: 'Confirm and enable 2FA',
    requiredInput: 'Field is required',
    confirmh1: '2FA successfully enable',
    confirmp1:
      '2 Factor Authentication is now setup on your account. Next time you login, after you enter your email and password, you will be asked to enter a One Time Code, generated by your chosen app. Simply open your One Time Code generator app, enter the generated code before the timer ends and hit Submit.',
    confirmp2: 'Save recovery codes',
    confirmp3:
      'Use these one-time recovery codes to access your account when you can receive codes from your authentication app:',
    confirmBtn: 'Got it!',
    errors: {
      invalid_code: 'The provided code is invalid.',
      invalid_recovery_code: 'The provided recovery code is invalid.',
      throttle: 'Too many attempts. Please wait before retrying.',
    },
  },
  mfaConfirm: {
    h1: '2FA Authentication',
    btnText: 'Send',
    recoveryText: 'Having problems? Use recovery codes.',
    recoveryH1: 'Recovery 2FA',
    recoveryInfoText: 'Please enter the Recovery Code you received when you setup your Two-Factor Authentication',
    recoveryLabel: 'Enter recovery code',
  },
  productSingle: {
    createdOn: 'Created on:',
    sendMsgBtn: 'Send message',
  },
  productStatus: {
    statusLabel: 'Status',
    new: 'New',
    moderate: 'Checking with moderator',
    active: 'Active',
    sold: 'Selled',
    reserved: 'Reserved',
    disabled: 'Disabled',
    canceled: 'Canceled',
  },
  settings: {
    account: 'Account',
    profile: 'Profile',
    security: 'Security',
  },
  password: {
    passwordH2: 'Password',
    mfaH2: '2FA Authentication',
    mfaDisableText: 'You have two-factor authentication enabled. To disable - click the button below.',
    oldPasswordLabel: 'Old password',
    passwordLabel: 'New password',
    passwordConfirmLabel: 'Confirm new password',
    mfaDisableTextBtn: 'Disable 2FA',
    recoveryCodesLabel: 'Your recovery codes',
  },
  profile: {
    emailVerified: 'Verified on: ',
    emailNotVerified: 'Not verified!',
    nameLabel: 'Name',
    surnameLabel: 'Surname',
    phoneNumber: 'Phone number',
    city: 'City',
    street: 'Street',
    birthdayDate: 'Birthday date',
    saveBtn: 'Save',
    h2: 'Profile',
    cancelBtn: 'Cancel',
  },
  socialShare: {
    shareTitle: 'By sharing this ad you are helping our site improve.',
  },
  verifyEmail: {
    titleConfirmed: 'Your email is already confirmed',
    titleNotConfirmed: 'Please confirm verification of your email',
    goHomeBtn: 'Go to main page',
    verifyBtn: 'Click to Verify',
    verifyBtnError: 'Oops! Resend Verification?',
  },
  googleAnalytics: {
    text: 'We use cookies to improve user experience. You consent to the use of cookies by clicking "Accept all cookies". You can also opt out.',
    consentText: 'Accept all cookies',
    refusalText: 'Reject',
  },
  sameProductsInTheCity: {
    h2: 'Same products in this city',
  },
  sameProducts: {
    h2: 'Same products in this category',
  },
};
