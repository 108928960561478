export default {
  namespaced: true,
  state() {
    return {
      products: [],
      pagination: {
        currentPage: 1,
        lastPage: 10,
      },
      cities: [], // Array{Object}
      regions: [], // Array{Object}
      userProducts: [],
      adminProducts: [],
      catalogs: [],
      catalogsFlatten: [],
    };
  },
  getters: {
    products: (state) => state.products,
    pagination: (state) => state.pagination,
    cities: (state) => state.cities,
    regions: (state) => state.regions,
    userProducts: (state) => state.userProducts,
    adminProducts: (state) => state.adminProducts,
    catalogs: (state) => state.catalogs,
    catalogsFlatten: (state) => state.catalogsFlatten,
  },
  mutations: {
    setProducts(state, { products }) {
      state.products = products;
    },

    setUserProducts(state, { products }) {
      state.userProducts = products;
    },

    setAdminProducts(state, { products }) {
      state.adminProducts = products;
    },

    setPaginationParams(state, { pagination }) {
      Object.assign(state.pagination, pagination);
    },

    setCities(state, { cities }) {
      state.cities = cities;
    },

    setRegions(state, { regions }) {
      state.regions = regions;
    },

    setCatalogsTree(state, { catalogs }) {
      state.catalogs = catalogs;
    },

    setCatalogsFlatten(state, { catalogs }) {
      state.catalogsFlatten = catalogs;
    },

    changeProductStatus(state, { product }) {
      if (state.adminProducts.find((product) => product.id === product.id)) {
        state.adminProducts.find((product) => product.id === product.id).status = product.status;
      }

      if (state.userProducts.find((product) => product.id === product.id)) {
        state.userProducts.find((product) => product.id === product.id).status = product.status;
      }
    },
  },
  actions: {
    async getProducts({ commit }, { page = null, filters = null }) {
      const params = { page };
      if (filters?.cities && filters?.cities?.length) params.cities = filters.cities;
      if (filters?.regions && filters?.regions?.length) params.regions = filters.regions;
      if (filters?.name) params.name = filters.name;
      if (filters?.catalogs) params.catalogs = filters.catalogs;

      await this.$axios
        .post('/products', params)
        .then((res) => {
          commit('setProducts', { products: res.data.data });
          commit('setPaginationParams', {
            pagination: {
              currentPage: res.data['current_page'],
              lastPage: res.data['last_page'],
            },
          });
        })
        .catch((err) => {
          throw err.response;
        });
    },

    async getProduct(_, { id }) {
      return await this.$axios.get(`/product/${id}`).catch((err) => {
        throw err.response;
      });
    },

    async getSameProductsInTheCity(_, { catalogId, cityId, currentProductId }) {
      const params = { catalogId, cityId, currentProductId };

      return await this.$axios.post(`/same-products-in-the-city/`, params).catch((err) => {
        throw err.response;
      });
    },

    async getSameProducts(_, { catalogId, cityId, currentProductId }) {
      const params = { catalogId, cityId, currentProductId };

      return await this.$axios.post(`/same-products/`, params).catch((err) => {
        throw err.response;
      });
    },

    async getCities({ commit }) {
      return await this.$axios
        .get(`/cities`)
        .then((r) => {
          commit('setCities', { cities: r.data });
        })
        .catch((err) => {
          throw err.response;
        });
    },

    async getRegions({ commit }) {
      await this.$axios
        .get('/regions')
        .then((res) => {
          commit('setRegions', { regions: res.data });
        })
        .catch((err) => {
          throw err.response;
        });
    },

    async getCatalogsTree({ commit }) {
      await this.$axios
        .get('/catalogs-tree')
        .then((res) => {
          commit('setCatalogsTree', { catalogs: res.data });
        })
        .catch((err) => {
          throw err.response;
        });
    },

    async getCatalogsFlatten({ commit }) {
      await this.$axios
        .get('/catalogs-flatten')
        .then((res) => {
          commit('setCatalogsFlatten', { catalogs: res.data });
        })
        .catch((err) => {
          throw err.response;
        });
    },

    async saveProduct(_, { product }) {
      return await this.$axios.post(
        '/product',
        { ...product },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },

    async updateProduct(_, { product, productUuid }) {
      return await this.$axios.patch(
        '/product',
        { ...product, productUuid },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
    },

    async uploadProductFiles(_, { files, productUuid }) {
      return await this.$axios.post(
        '/product-images',
        { files, productUuid },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },

    async deleteProductImages(_, { imageUuid, productUuid }) {
      return await this.$axios.post(
        '/delete-product-images',
        { imageUuid, productUuid },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },

    async getUserProducts({ commit }) {
      await this.$axios.get('/user-products').then((res) => {
        commit('setUserProducts', { products: res.data });
      });
    },

    async getAdminProducts({ commit }, { status, page }) {
      await this.$axios
        .post('/admin/products', {
          status,
          page,
        })
        .then((res) => {
          commit('setAdminProducts', { products: res.data.data });
          commit('setPaginationParams', {
            pagination: {
              currentPage: res.data['current_page'],
              lastPage: res.data['last_page'],
            },
          });
        });
    },

    async changeProductStatus(_, { id, status }) {
      return await this.$axios.patch('/product/change-status', { id, status });
    },

    async searchTyping(_, { query }) {
      return await this.$axios.post('/search-hint', { query });
    },
  },
};
