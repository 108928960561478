const freeze = Object.freeze.bind(Object);

export const AUTH_MENU = ({ $i18n }) => {
  return {
    login: freeze({
      text: $i18n.messages[$i18n.locale].leftNavigationDrawer.login,
      key: 'login',
      to: { name: 'login' },
      exact: true,
      icon: 'mdi-login-variant',
    }),
    register: freeze({
      text: $i18n.messages[$i18n.locale].leftNavigationDrawer.register,
      key: 'register',
      to: { name: 'register' },
      exact: true,
      icon: 'mdi-account-plus',
    }),
  };
};

export const USER_MENU = ({ $i18n }) => {
  return {
    login: freeze({
      text: $i18n.messages[$i18n.locale].leftNavigationDrawer.account,
      key: 'My account',
      to: { name: 'profile' },
      exact: true,
      icon: 'mdi-account-circle',
    }),
    wish: freeze({
      text: $i18n.messages[$i18n.locale].leftNavigationDrawer.wishes,
      key: 'My wishes',
      to: { name: 'wishes' },
      exact: true,
      icon: 'mdi-heart-outline',
    }),
    messenger: freeze({
      text: $i18n.messages[$i18n.locale].leftNavigationDrawer.messenger,
      key: 'Messenger',
      to: { name: 'messenger' },
      exact: true,
      icon: 'mdi-message-fast-outline',
    }),
    createProduct: freeze({
      text: $i18n.messages[$i18n.locale].leftNavigationDrawer.createProduct,
      key: 'Create product',
      to: { name: 'new-ad' },
      exact: true,
      icon: 'mdi-plus-circle-outline',
    }),
    userProducts: freeze({
      text: $i18n.messages[$i18n.locale].leftNavigationDrawer.myShowCase,
      key: 'My showcase',
      to: { name: 'my-showcase' },
      exact: true,
      icon: 'mdi-newspaper',
    }),
  };
};

export const PRODUCTS_VIEW = freeze({
  LIST: 'LIST',
  CARD: 'CARD',
});

export const DEFAULT_PRODUCTS_PAGE_SIZE = 20;

export const DEFAULT_MESSAGES_PAGE_SIZE = 20;

export const PARENT_CATEGORIES = freeze({
  transport: freeze({
    icon: 'mdi-car',
  }),
  'real-estate': freeze({
    icon: 'mdi-home-city',
  }),
  jobs: freeze({
    icon: 'mdi-account-hard-hat',
  }),
  'personal-items': freeze({
    icon: 'mdi-hanger',
  }),
  'business-and-equipment': freeze({
    icon: 'mdi-handshake',
  }),
  animals: freeze({
    icon: 'mdi-cat',
  }),
  'hobbies-and-recreation': freeze({
    icon: 'mdi-hockey-sticks',
  }),
  'parts-and-accessories': freeze({
    icon: 'mdi-cog',
  }),
  electronics: freeze({
    icon: 'mdi-monitor-cellphone',
  }),
  services: freeze({
    icon: 'mdi-account-wrench',
  }),
});

export const PRODUCT_STATUSES = freeze({
  new: 'new',
  moderate: 'moderate',
  active: 'active',
  sold: 'sold',
  reserved: 'reserved',
  disabled: 'disabled',
  canceled: 'canceled',
});

export const USER_PRODUCT_STATUSES = freeze({
  active: 'active',
  sold: 'sold',
  reserved: 'reserved',
  disabled: 'disabled',
});

export const USER_ROLES = freeze({
  superAdmin: 'superAdmin',
  admin: 'admin',
  moderator: 'moderator',
  editor: 'editor',
  user: 'user',
});

export const SOCIAL_NETWORKS = freeze({
  facebook: {
    name: 'facebook',
    icon: 'fa-lg fa-facebook',
  },
  telegram: {
    name: 'telegram',
    icon: 'fa-lg fa-telegram',
  },
  whatsapp: {
    name: 'whatsapp',
    icon: 'fa-lg fa-whatsapp',
  },
  twitter: {
    name: 'twitter',
    icon: 'fa-lg fa-twitter',
  },
});
