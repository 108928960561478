// import Vue from 'vue';
import { createStore } from 'vuex';
import sharedMutations from 'vuex-shared-mutations';
import auth from './modules/auth';
import toast from './modules/toast';
import sidebar from './modules/sidebar';
import ui from './modules/ui';
import products from './modules/products';
import wishes from './modules/wishes';
import modals from './modules/modals';
import chats from './modules/chats';
import persistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    //
  },
  mutations: {
    //
  },
  actions: {
    //
  },
  modules: {
    auth,
    toast,
    sidebar,
    ui,
    products,
    wishes,
    chats,
    modals,
  },
  plugins: [
    sharedMutations({
      predicate: ['SET_USER', 'SET_AUTHENTICATION', 'SET_THEME'],
    }),
    persistedState({
      storage: window.sessionStorage,
      reducer: (state) => {
        const reducer = Object.assign({}, state);
        delete reducer.modals;
        delete reducer.products;
        delete reducer.wishes;
        delete reducer.chats;

        return reducer;
      },
    }),
  ],
});
